import React from 'react'
import { useStaticQuery, graphql, Link as RouterLink } from 'gatsby'

import {
  Section,
  Container,
  Row,
  Column,
  Box,
  Button,
  Heading,
  Text,
} from 'components'

import Accordion from 'components/Accordion'

const Faq = ({ subheadingText = '', backgroundColor = 'white' }) => {
  const data = useStaticQuery(
    graphql`
      query($slug: String = "haeufige-fragen") {
        currentCategory: allContentfulFaqCategory(
          filter: { slug: { eq: $slug } }
        ) {
          nodes {
            ...Category
          }
        }
        faqs: allContentfulFaq(
          filter: { category: { elemMatch: { slug: { eq: $slug } } } }
          sort: { fields: orderNumberLanding, order: DESC }
        ) {
          nodes {
            question
            answer {
              json
            }
            id
          }
        }
      }
    `
  )

  const {
    currentCategory: {
      nodes: [{ slug: categorySlug }],
    },
    faqs: { nodes: faqs },
  } = data

  return (
    <Section spacing="large" bg={backgroundColor}>
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center">Häufig gestellte Fragen</Heading.H2>
          {subheadingText && (
            <Text textAlign="center" mt={3}>
              {subheadingText}
            </Text>
          )}
        </Section.Header>

        <Section.Body>
          <Row justifyContent="center">
            <Column size={[1, 21 / 24]}>
              <Box bg="colorBlue2">
                <Accordion questions={faqs} slug={categorySlug} />
              </Box>
            </Column>
          </Row>
        </Section.Body>

        <Section.Footer textAlign="center">
          <Button
            variant="outline"
            as={RouterLink}
            py={1}
            to="/faq/haeufige-fragen"
          >
            Alle Fragen & Antworten
          </Button>
        </Section.Footer>
      </Container>
    </Section>
  )
}

export default Faq
