import React from 'react'

import { Section, Container, Grid, Heading, Text, Box } from 'components'

import Article from 'screens/Guide/components/Article'

/**
 *
 * @param subheadingText - Simple text to be shown as subheading
 * @param subheadingElement - React node to be shown as subheading
 */
const Blog = ({
  title,
  subheadingText,
  subheadingElement,
  articles = [],
  backgroundColor = 'colorBlue2',
}) => {
  return (
    <Section bg={backgroundColor} spacing="large">
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" color="colorBlue90">
            {title}
          </Heading.H2>
          {subheadingText && (
            <Text textAlign="center" mt={3} color="colorBlue90">
              {subheadingText}
            </Text>
          )}
          {subheadingElement && <Box mt={3}>{subheadingElement}</Box>}
        </Section.Header>

        <Section.Body>
          <Grid gap={['56px', '40px']} columns={[1, 2, 2, 3]}>
            {articles.map((article) => (
              <Article key={article.id} {...article} />
            ))}
          </Grid>
        </Section.Body>
      </Container>
    </Section>
  )
}

export default Blog
