import React from 'react'
import { Button } from 'components'
import { navigate, useLocation } from '@reach/router'

export const LEAD_MODAL_TYPES = {
  STANDARD_OFFER: 'standard_offer',
  STANDARD_CALLBACK: 'standard_callback',
  STANDARD_LOCKED_OFFER: 'standard_locked_offer',
  REDUCED: 'reduced',
  TEILVERKAUF: 'teilverkauf',
}

const getHash = (leadModalType) => {
  switch (leadModalType) {
    case 'standard_offer':
      return '#anmelden'
    case 'standard_callback':
      return '#anmeldenCallbackMode'
    case 'standard_locked_offer':
      return '#anmeldenLockedOfferMode'
    case 'reduced':
      return '#reducedAnmelden'
    case 'teilverkauf':
      return '#anmeldenTeilverkaufModal'
    case 'teilverkauf_callback':
      return '#anmeldenCallbackModeTeilverkaufModal'
    case 'brochure':
      return '#brochureAnmelden'
    default:
      return '#anmelden'
  }
}

const LeadCTAButton = ({
  children,
  leadModalType = 'teilverkauf',
  sx = {},
  signupReason,
  amount,
  serviceDimension,
  formMetadataLSKey,
  onClick: onClickProp,
  ctaButtonID,
  propertyWorth,
  payout,
  ...rest
}) => {
  const isExternalSource = !!rest.href

  // We throw an error when there is no ID set on the button as we need one for each and every instance
  // so we can better identify them through analytics.
  // Also, when an external source is set, we don't deem it necessary either as it's not for an internal lead.
  if (!ctaButtonID && !isExternalSource) {
    throw new Error('LeadCTAButton must have an ID!')
  }

  const location = useLocation()

  const onClick = (event) => {
    if (onClickProp) {
      onClickProp()
    }

    // If this button links to an external resource we skip the important internal logic
    if (isExternalSource) {
      return
    }

    // Send event to analytics
    if (ctaButtonID) {
      const eventName = `cta_button_click-${ctaButtonID}`
      // Send event to GTM
      window.dataLayer &&
        window.dataLayer.push({
          event: eventName,
        })

      // Send event to Matomo
      window._paq && window._paq.push(['trackEvent', eventName, eventName])
    }

    if (
      signupReason ||
      formMetadataLSKey ||
      amount ||
      serviceDimension ||
      propertyWorth ||
      payout
    ) {
      event.preventDefault()
      const searchParams = new URLSearchParams(location.search)
      if (signupReason) {
        searchParams.set('lead_signup_reason', signupReason)
      }
      if (formMetadataLSKey) {
        searchParams.set('lead_form_metadata_ls_key', formMetadataLSKey)
      }
      if (amount) {
        searchParams.set('lead_amount', amount)
      }
      if (serviceDimension) {
        searchParams.set('service_dimension', serviceDimension)
      }
      if (propertyWorth) {
        searchParams.set('property_worth', propertyWorth)
      }
      if (payout) {
        searchParams.set('payout', payout)
      }
      navigate(
        `${location.pathname}?${searchParams.toString()}${getHash(
          leadModalType
        )}`,
        { replace: true }
      )
    }
  }

  return (
    <Button
      variant="main"
      as="a"
      href={getHash(leadModalType)}
      sx={{
        width: '100%',
        textAlign: 'center',
        ...sx,
      }}
      {...rest}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default LeadCTAButton
