import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import BambusFontURL1 from 'fonts/1Ptvg83HX_SGhgqk0QotYKNnBcif.woff2'
import BambusFontURL2 from 'fonts/1Ptvg83HX_SGhgqk3wotYKNnBQ.woff2'

const SEO = ({
  lang = 'de',
  meta = [],
  title,
  description = '',
  type,
  image,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            defaultImage: image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaType = type || `website`
  const metaImage = image || site.siteMetadata.defaultImage

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: site.siteMetadata.keywords.concat(','),
        },
        {
          property: `og:title`,
          content: title || site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: metaType,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title || site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link
        rel="preload"
        as="font"
        href={BambusFontURL1}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={BambusFontURL2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      {/* Preloading our hero image (webp only) */}
      <link
        rel="preload"
        as="image"
        href="/hero-illustration/6050d/hero-iso-compressed.png"
        imagesrcset="/hero-illustration/0bc1d/hero-iso-compressed.webp 300w,
          /hero-illustration/fc91b/hero-iso-compressed.webp 600w,
          /hero-illustration/9000d/hero-iso-compressed.webp 1200w,
          /hero-illustration/60b4e/hero-iso-compressed.webp 1800w,
          /hero-illustration/83a9d/hero-iso-compressed.webp 2400w,
          /hero-illustration/bb3ce/hero-iso-compressed.webp 2730w"
        imagesizes="(max-width: 1200px) 100vw, 1200px"
      ></link>
      {/* Preloading our TVSituations images */}
      <link rel="preload" as="image" href="/tv-situations/0.webp"></link>
      <link rel="preload" as="image" href="/tv-situations/1.webp"></link>
      <link rel="preload" as="image" href="/tv-situations/2.webp"></link>
      <link rel="preload" as="image" href="/tv-situations/3.webp"></link>
      <link rel="preload" as="image" href="/tv-situations/4.webp"></link>
      <link rel="preload" as="image" href="/tv-situations/5.webp"></link>
      <link rel="preload" as="image" href="/tv-situations/6.webp"></link>
    </Helmet>
  )
}

export default SEO
