import React, { Fragment, useState } from 'react'
import useCollapse from 'react-collapsed'
import RichTextRenderer from 'components/RichTextRenderer'

import {
  HelpCircle,
  Database,
  TrendingUp,
  Book,
  ChevronUp,
  ChevronDown,
  ThumbsUp,
} from 'icons'

import { Flex, Box, Heading, Text, Icon, Hr } from 'components'

const icons = {
  'haeufige-fragen': HelpCircle,
  finanzierung: Database,
  veranlagung: TrendingUp,
  rechtliches: Book,
  advice: ThumbsUp,
  transaktion: TrendingUp,
  kosten: Database,
  miteigentuemerschaft: Book,
  sonstiges: HelpCircle,
}

const collapseStyles = {
  transitionDuration: '500ms',
  transitionTimingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
}

const expandStyles = {
  transitionDuration: '500ms',
  transitionTimingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
}

const Question = (props) => {
  const {
    icon,
    question,
    answer: { json: answer },
    open = false,
    onToggle,
    nonRichTextContent,
  } = props

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: open,
    collapseStyles,
    expandStyles,
  })
  const toggleProps = getToggleProps({
    onClick: () => onToggle(open),
  })
  const collapseProps = getCollapseProps()

  return (
    <Box py={2} px={[4, 6]}>
      <Flex
        py={3}
        sx={{
          cursor: 'pointer',
          outline: 'none',
        }}
        {...toggleProps}
      >
        {icon && (
          <Icon source={icon} size={18} mt={['2px', '4px']} color="turquoise" />
        )}

        <Heading.H5 flexGrow="1" pl={3} color="colorBlue100">
          {question}
        </Heading.H5>

        <Icon
          source={open ? ChevronUp : ChevronDown}
          size={20}
          mt="1px"
          color={open ? 'colorBlue100' : 'colorBlue50'}
        />
      </Flex>
      <div {...collapseProps}>
        <Text pt={[0, 2]} pb={[2, 4]}>
          {nonRichTextContent ? (
            props.answer
          ) : (
            <RichTextRenderer>{answer}</RichTextRenderer>
          )}
        </Text>
      </div>
    </Box>
  )
}

const FAQ = (props) => {
  const { questions = [], slug, nonRichTextContent = false } = props
  const [activeQuestion, setActiveQuestion] = useState(questions[0].id)

  return (
    <Box variant="card.outline" mx={[0]} py={[0, null, null, 3]}>
      {questions.map(({ id, ...question }, index) => {
        return (
          <Fragment key={id}>
            <Question
              id={id}
              icon={icons[slug]}
              {...question}
              open={id === activeQuestion}
              nonRichTextContent={nonRichTextContent}
              onToggle={() => {
                if (activeQuestion === id) {
                  setActiveQuestion(null)
                } else {
                  setActiveQuestion(id)
                }
              }}
            />
            {index + 1 < questions.length && (
              <Hr my={0} mx={[0, 4]} width="auto" />
            )}
          </Fragment>
        )
      })}
    </Box>
  )
}

export default FAQ
