import React from 'react'
import { FileText, Award } from 'icons'
import { Section, Container, Box, Flex, Heading, Text, Image } from 'components'

const RegulationsAustria = () => (
  <Flex
    sx={{
      borderRadius: 'default',
      alignItems: 'center',
      bg: '#BFC7D3',
      px: 3,
      py: 3,
      maxWidth: 372,
    }}
  >
    <Box as={Award} flexShrink={0} />
    <Box pl={3} flexGrow={1}>
      <Text fontSize={0} fontWeight="bold">
        Gewerbeberechtigungen
      </Text>
      <Text fontSize={0}>Gewerbliche Vermögensberatung</Text>
      <Text fontSize={0}>(ohne Lebens- und Unfallversicherung)</Text>
    </Box>
  </Flex>
)

const RegulationsGermany = () => (
  <Flex
    sx={{
      borderRadius: 'default',
      alignItems: 'center',
      bg: '#BFC7D3',
      px: 3,
      py: 3,
      maxWidth: 372,
    }}
  >
    <Box as={Award} flexShrink={0} />
    <Box pl={3} flexGrow={1}>
      <Text fontSize={0} fontWeight="bold">
        Gewerbeberechtigungen
      </Text>
      <Text fontSize={0}>Immobiliendarlehensvermittlung § 34i</Text>
      <Text fontSize={0}>Darlehensvermittlung § 34c</Text>
    </Box>
  </Flex>
)

const SslBadge = () => (
  <Flex
    sx={{
      borderRadius: 'default',
      alignItems: 'center',
      bg: '#F2DC5D',
      px: 3,
      py: 3,
      maxWidth: 372,
    }}
  >
    <Box as={Award} flexShrink={0} />
    <Box pl={3} flexGrow={1}>
      <Text fontSize={0} fontWeight="bold">
        Sicher
      </Text>
      <Text fontSize={0}>SSL Verschlüsselung</Text>
      <Text fontSize={0}>Datacenter in Deutschland</Text>
    </Box>
  </Flex>
)

const Regulations = ({ country, backgroundColor = 'white' }) => {
  return (
    <Section spacing="large" bg={backgroundColor}>
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center">Zugelassen und Reguliert</Heading.H2>
        </Section.Header>

        <Section.Body>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              py: [6, 0],
              m: [-3],
            }}
          >
            <Box p={[3]}>
              {country === 'AT' ? (
                <RegulationsAustria />
              ) : (
                <RegulationsGermany />
              )}
            </Box>

            <Box
              p={[3]}
              sx={{
                '&>img': {
                  width: 128,
                  height: 125,
                  mx: 'auto',
                },
              }}
            >
              <img
                display="block"
                width={128}
                height={125}
                loading="lazy"
                src="/financial-service-provider.png"
                alt="Standesregeln Finanzdienstleister"
                role="presentation"
              />
            </Box>

            <Box p={[3]}>
              <SslBadge />
            </Box>
          </Flex>
        </Section.Body>
      </Container>
    </Section>
  )
}

export default Regulations
