import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ProgressiveImage from 'gatsby-image'

import {
  Section,
  Container,
  Row,
  Column,
  Flex,
  Box,
  Heading,
  Text,
  Icon,
} from 'components'

import Trust from 'components/Trust'
import LeadCTAButton from 'components/LeadCTAButton'

import Quote from 'images/quote.svg'

const DEFAULT_TESTIMONIALS = [
  {
    title: 'Tolles Service!',
    description:
      'Die günstigste Lösung für mein Haus - schnell und unkompliziert!',
    image: 'karoline',
    name: 'Karoline B.',
  },
  {
    title: 'Kompetente Beratung.',
    description:
      'Seriöse und kompetente Beratung mit enger Begleitung bis zur Auszahlung.',
    image: 'manuel',
    name: 'Manuel N.',
  },
  {
    title: 'So muss Service aussehen',
    description:
      'Von der ersten Anfrage bis zum Vertrag eine perfekte Betreuung.',
    image: 'michael',
    name: 'Michael B.',
  },
]

const Testimonials = ({
  testimonials = DEFAULT_TESTIMONIALS,
  showCTA = false,
  textCTA = 'Jetzt unverbindlich anfragen',
  backgroundColor = 'white',
  userStory,
  ctaButtonID,
  leadModalType = 'teilverkauf',
}) => {
  const images = useStaticQuery(
    graphql`
      query {
        karoline: file(
          relativePath: { eq: "testimonials/testimonial-karoline.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 368, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        manuel: file(
          relativePath: { eq: "testimonials/testimonial-manuel.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 368, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        michael: file(
          relativePath: { eq: "testimonials/testimonial-michael.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 368, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  const computedTestimonials = testimonials.map(({ image, ...rest }) => ({
    ...rest,
    image: images[image],
  }))

  return (
    <Section spacing="large" sx={{ backgroundColor }}>
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" color="colorBlue90">
            Das sagen unsere Kunden
          </Heading.H2>
        </Section.Header>

        <Section.Body>
          {userStory && userStory}
          <Row {...(userStory && { sx: { marginTop: 5 } })}>
            {computedTestimonials.map(({ title, description, image, name }) => (
              <Column
                key={name}
                size={[1, null, null, 1 / 3]}
                display="flex"
                justifyContent="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  variant="card.secondary"
                  maxWidth={346}
                  my={2}
                  pt={[6]}
                  px={[4, 5]}
                  pb={4}
                  sx={{ position: 'relative' }}
                >
                  <Icon
                    source={Quote}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 30,
                    }}
                  />
                  <Heading.H4 mb={3} fontSize="21px" color="colorBlue100">
                    {title}
                  </Heading.H4>
                  <Text fontSize={2} mb="auto" pb={[4, 6]}>
                    {description}
                  </Text>
                  <Flex alignItems="center">
                    <Box
                      variant="avatar.testimonial"
                      flexGrow="0"
                      flexShrink="0"
                    >
                      <ProgressiveImage
                        fluid={image?.childImageSharp.fluid}
                        alt={name}
                      />
                    </Box>
                    <Text flexGrow="1" fontSize={2} fontWeight="bold" pl={4}>
                      {name}
                    </Text>
                  </Flex>
                </Box>
              </Column>
            ))}
          </Row>
        </Section.Body>
        <Section.Footer sx={{ textAlign: 'center' }}>
          <Trust />
          {showCTA && (
            <LeadCTAButton
              ctaButtonID={ctaButtonID}
              leadModalType={leadModalType}
              variant="main"
              sx={{
                width: ['100%', 'auto'],
                maxWidth: 300,
                mx: ['auto', 0],
                mt: 5,
              }}
            >
              {textCTA}
            </LeadCTAButton>
          )}
        </Section.Footer>
      </Container>
    </Section>
  )
}

export default Testimonials
