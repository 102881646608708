import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Doughnut } from 'react-chartjs-2'
import { Chart } from 'chart.js'

import usePrevious from 'hooks/usePrevious'
import LeadCTAButton, { LEAD_MODAL_TYPES } from 'components/LeadCTAButton'
import Slider from 'components/Forms/Slider'
import { Text, Box, Section, Container, Heading } from 'components'

// Set this to true in order to show data labels on desktop next to the Doughnut chart slices
const SHOW_DATA_LABELS_ON_DESKTOP = false
if (SHOW_DATA_LABELS_ON_DESKTOP) {
  Chart.register(ChartDataLabels)
}

const OWN_SHARE_COLOR = '#966B9D'
const BAMBUS_SHARE_COLOR = '#1A3F69'

const PROPERTY_VALUATION_DEFAULT_VALUE = 350000
const FEE_COEFFICIENT = 0.055
const PAYOUT_DEFAULT_VALUE = 80000

const yupCalculatorSchema = yup.object().shape({
  property_valuation: yup.number(),
  payout: yup.number(),
})
const calculatorValidationSchema = yupResolver(yupCalculatorSchema)

const getChartData = (ownerShare, bambusShare) => ({
  labels: ['Ihr Anteil', 'Bambus Anteil'],
  datasets: [
    {
      data: [ownerShare, bambusShare],
      backgroundColor: [OWN_SHARE_COLOR, BAMBUS_SHARE_COLOR],
      borderColor: [OWN_SHARE_COLOR, BAMBUS_SHARE_COLOR],
    },
  ],
})

const getChartOptions = (enableDatalabels) => ({
  ...(enableDatalabels && {
    layout: {
      padding: {
        left: 200,
        right: 200,
        top: 100,
        bottom: 100,
      },
    },
  }),
  maintainAspectRatio: !enableDatalabels,
  animation: {
    animateRotate: false,
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    datalabels: {
      display: enableDatalabels,
      align: 'end',
      offset: 30,
      font: {
        family: 'Mulish',
        size: 18,
        weight: 'bold',
      },
      formatter: function (value, context) {
        return `${
          context.chart.data.labels[context.dataIndex]
        }\n€ ${value.toLocaleString('de-DE')}`
      },
      color: function (context) {
        return context.dataset.backgroundColor[context.dataIndex]
      },
    },
  },
  cutout: '80%',
})

const Calculator = ({
  titleID = null,
  title = null,
  useAlwaysColumnLayout = false,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    errors,
    getValues,
    setValue,
    formState,
    control,
    watch,
  } = useForm({
    resolver: calculatorValidationSchema,
    defaultValues: {
      initial_signup_reason: 'offer',
      property_valuation: PROPERTY_VALUATION_DEFAULT_VALUE,
      payout: PAYOUT_DEFAULT_VALUE,
    },
  })

  // Output fields
  const [bambusShare, setBambusShare] = useState(0)
  const [usageFee, setUsageFee] = useState(0)

  /**
   * Hacky solution below!
   * The plugin "chartjs-plugin-datalabels" (that adds labels to the chart elements) most probably
   * has a bug: it doesn't render the custom font of Bambus (Mulish) unless the chart intro animation is turned on (which is not).
   * Thus, we are introducing a delay for its rendering that apparently solves the problem.
   */
  const [shouldRenderChart, setShouldRenderChart] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => {
      setShouldRenderChart(true)
    }, 0)
  }, [])

  // Below 700px inner width we disable the chart pieces' labels as they take up too much space
  const [areChartLabelsEnabled, setAreChartLabelsEnabled] =
    React.useState(false)
  React.useEffect(() => {
    const TRESHOLD = 800
    setAreChartLabelsEnabled(window.innerWidth > TRESHOLD)

    const resizeHandler = () => {
      setAreChartLabelsEnabled(window.innerWidth > TRESHOLD)
    }
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const fieldValues = watch()
  const prevFieldValues = usePrevious(fieldValues)

  const payoutMaxValue =
    fieldValues.property_valuation >= 200000
      ? Math.round(fieldValues.property_valuation * 0.5)
      : fieldValues.property_valuation

  const calculateOutput = React.useCallback(
    throttle((values) => {
      setBambusShare(
        Math.round((values.payout / values.property_valuation) * 100)
      )
      setUsageFee(Math.round(((values.payout * FEE_COEFFICIENT) / 12) * 1.1))
    }, 0),
    []
  )

  React.useEffect(() => {
    if (!isEqual(fieldValues, prevFieldValues)) {
      calculateOutput(fieldValues)
    }
  }, [fieldValues])

  // First time the user interacts with the calculator we send an analytics event (so we can track the interaction)
  const wasInteractionAnalyticsEventSent = React.useRef(false)
  React.useEffect(() => {
    if (formState.isDirty && !wasInteractionAnalyticsEventSent.current) {
      wasInteractionAnalyticsEventSent.current = true
      window.dataLayer &&
        window.dataLayer.push({
          event: 'teilverkauf_calculator_interaction',
        })
      window._paq &&
        window._paq.push([
          'trackEvent',
          'teilverkauf_calculator_interaction',
          'teilverkauf_calculator_interaction',
          'teilverkauf_calculator_interaction',
        ])
    }
  }, [formState])

  const chartData = React.useMemo(() => {
    return getChartData(100 - bambusShare, bambusShare)
  }, [bambusShare])

  const DoughnutChart = React.useMemo(() => {
    if (!shouldRenderChart) return null

    return (
      <Doughnut
        data={chartData}
        options={getChartOptions(
          areChartLabelsEnabled && SHOW_DATA_LABELS_ON_DESKTOP
        )}
      />
    )
  }, [chartData, shouldRenderChart, areChartLabelsEnabled])

  return (
    <Section>
      {title && (
        <Section.Header sx={{ marginBottom: 40 }}>
          {titleID && (
            <Box
              id={titleID}
              sx={{
                position: 'relative',
                top: [-64 - 20, null, null, -88 - 35],
              }}
            />
          )}
          <Heading.H2 textAlign="center" color="colorBlue90">
            {title}
          </Heading.H2>
        </Section.Header>
      )}
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            padding: useAlwaysColumnLayout
              ? '20px'
              : ['20px', null, null, '30px'],
            borderRadius: '12px',
            boxShadow: 'md',
            width: '100%',
          }}
        >
          <Box>
            <Text
              sx={{
                textAlign: 'center',
                fontSize: 4,
                fontWeight: 700,
                marginBottom: useAlwaysColumnLayout ? 20 : [20, null, 0],
              }}
            >
              Auszahlung berechnen
            </Text>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: useAlwaysColumnLayout
                ? 'column'
                : ['column', null, 'row'],
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: useAlwaysColumnLayout ? '100%' : ['100%', null, '50%'],
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: useAlwaysColumnLayout
                    ? 'stretch'
                    : ['stretch', null, 'start'],
                  padding: useAlwaysColumnLayout
                    ? '10px'
                    : ['10px', null, '30px'],
                  paddingTop: useAlwaysColumnLayout
                    ? '10px'
                    : ['10px', null, '60px'],
                }}
              >
                <Text fontSize={2} fontWeight={600}>
                  Wert der Immobilie
                </Text>
                <Box style={{ width: '100%', maxWidth: '800px' }}>
                  <Controller
                    name="property_valuation"
                    control={control}
                    defaultValue={PROPERTY_VALUATION_DEFAULT_VALUE}
                    as={
                      <Slider
                        defaultValue={PROPERTY_VALUATION_DEFAULT_VALUE}
                        min={150000}
                        max={2000000}
                        step={10000}
                        minLabel="150.000 €"
                        maxLabel="2.000.000 €"
                        valueFormatter={(value) =>
                          `${value.toLocaleString('de')} €`
                        }
                      />
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: useAlwaysColumnLayout
                    ? 'stretch'
                    : ['stretch', null, 'start'],
                  padding: useAlwaysColumnLayout
                    ? '10px'
                    : ['10px', null, '30px'],
                }}
              >
                <Text fontSize={2} fontWeight={600}>
                  Gewünschte Auszahlung
                </Text>
                <Box style={{ width: '100%', maxWidth: '800px' }}>
                  <Controller
                    name="payout"
                    control={control}
                    defaultValue={PAYOUT_DEFAULT_VALUE}
                    as={
                      <Slider
                        defaultValue={PAYOUT_DEFAULT_VALUE}
                        min={80000}
                        max={payoutMaxValue}
                        step={1000}
                        minLabel="80.0000 €"
                        maxLabel={`${payoutMaxValue.toLocaleString('de')} €`}
                        valueFormatter={(value) =>
                          `${value.toLocaleString('de')} €`
                        }
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: useAlwaysColumnLayout ? '100%' : ['100%', null, '50%'],
                paddingTop: useAlwaysColumnLayout
                  ? '10px'
                  : ['10px', null, '20px'],
              }}
            >
              <Text fontSize={3} fontWeight="bold">
                Auszahlung
              </Text>
              <Box
                width="100%"
                pt={3}
                sx={{
                  position: 'relative',
                  ...(!SHOW_DATA_LABELS_ON_DESKTOP && {
                    maxWidth: '350px',
                  }),
                }}
                {...(areChartLabelsEnabled &&
                  SHOW_DATA_LABELS_ON_DESKTOP && { height: '532px' })}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '40px',
                    top: '50%',
                    transform: useAlwaysColumnLayout
                      ? 'translateY(-45%)'
                      : ['translateY(-45%)', null, 'translateY(-50%)'],
                    left: 0,
                    fontWeight: 'bold',
                    zIndex: 1000,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      sx={{
                        fontWeight: 800,
                        fontSize: [2, null, 4],
                        backgroundColor: 'purple',
                        color: 'white',
                        padding: '0px 7px',
                        marginBottom: '5px',
                      }}
                    >
                      {100 - bambusShare} %
                    </Text>
                    <Text>Ihr Anteil</Text>
                    <Text
                      sx={{
                        fontSize: 1,
                        fontWeight: 600,
                        color: 'colorBlue75',
                      }}
                    >
                      Gegenwert:{' '}
                      {(
                        fieldValues.property_valuation - fieldValues.payout
                      ).toLocaleString('de')}{' '}
                      €
                    </Text>
                  </Box>
                  <Box
                    sx={{
                      borderTop: '1px solid',
                      borderColor: 'colorBlue25',
                      margin: '10px 0',
                      width: '50%',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      sx={{
                        fontWeight: 800,
                        fontSize: [2, null, 4],
                        backgroundColor: 'colorBlue90',
                        color: 'white',
                        padding: '0px 7px',
                        marginBottom: '5px',
                      }}
                    >
                      {bambusShare} %
                    </Text>
                    <Text>Bambus Anteil</Text>
                    <Text
                      sx={{
                        fontSize: 1,
                        fontWeight: 600,
                        color: 'colorBlue75',
                      }}
                    >
                      Gegenwert: {fieldValues.payout.toLocaleString('de')} €
                    </Text>
                  </Box>
                </Box>
                {DoughnutChart}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              paddingLeft: useAlwaysColumnLayout
                ? '0px'
                : ['0px', null, null, '30px'],
              paddingTop: useAlwaysColumnLayout
                ? '24px'
                : ['24px', null, '0px'],
              fontSize: 1,
            }}
          >
            <Text>
              Ihre monatliche Nutzungsgebühr:&nbsp;
              {usageFee.toLocaleString('de')} €*
            </Text>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LeadCTAButton
              leadModalType={LEAD_MODAL_TYPES.TEILVERKAUF}
              signupReason="partial_ownership_calculator"
              formMetadataLSKey="teilverkauf_calculator_data"
              ctaButtonID="Teilverkauf--Calculator"
              onClick={() => {
                if (localStorage) {
                  localStorage.setItem(
                    'teilverkauf_calculator_data',
                    JSON.stringify({
                      ...fieldValues,
                      bambusShare,
                      usageFee,
                    })
                  )
                }
              }}
              sx={{
                maxWidth: '500px',
                mt: [4, 5],
              }}
              propertyWorth={fieldValues.property_valuation}
              payout={fieldValues.payout}
            >
              Unverbindlich anfragen
            </LeadCTAButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              paddingLeft: useAlwaysColumnLayout
                ? '0px'
                : ['0px', null, null, '30px'],
              paddingRight: useAlwaysColumnLayout
                ? '0px'
                : ['0px', null, null, '30px'],
              paddingTop: useAlwaysColumnLayout
                ? '24px'
                : ['24px', null, '24px'],
              fontSize: 0,
            }}
          >
            <Text>
              *Bei der hier berechneten Nutzungsgebühr handelt es sich um eine
              Schätzung. Die tatsächliche Nutzungsgebühr richtet sich nach
              marktüblichen Mietzinsen und kann abhängig von der Marktsituation
              von der Schätzung abweichen.
            </Text>
          </Box>
        </Box>
      </Container>
    </Section>
  )
}

export default Calculator
