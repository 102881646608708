import React, { useEffect, useRef, useState } from 'react'
import { Link as RouterLink } from 'gatsby'
import { usePopperTooltip } from 'react-popper-tooltip'
import useCollapse from 'react-collapsed'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useLocation as useRouterLocation } from '@reach/router'
import {
  Menu as MenuIcon,
  X as Close,
  User,
  ChevronUp,
  ChevronDown,
  Phone,
} from 'icons'
import { isBrowser } from 'utils'
import { PhoneLink } from 'components/ContactLink'
import Logo from 'components/Logo'
import LocationDropdown from 'components/LocationDropdown'
import {
  Section,
  Container,
  Row,
  Column,
  Flex,
  Box,
  Link,
  Button,
  Icon,
} from 'components'

import { partnerCTAProps } from 'screens/Home/partner'

import { useSharedState } from 'context/sharedState'

import { LOGIN_URL } from 'config'

const MenuItemInner = ({ to, children, ...props }) => {
  return (
    <Link as={RouterLink} variant="nav.headerInner" to={to} {...props}>
      {children}
    </Link>
  )
}

const Divider = () => {
  return (
    <Box
      sx={{
        padding: [3, null, null, 2],
        maxWidth: '300px',
        py: [1, null, null, 2],
      }}
    >
      <Box
        sx={{
          height: '1px',
          backgroundColor: 'colorBlue50',
        }}
      />
    </Box>
  )
}

const collapseStyles = {
  transitionDuration: '500ms',
  transitionTimingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
}

const expandStyles = {
  transitionDuration: '500ms',
  transitionTimingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
}

const ParentMenuItem = ({ id, title, children }) => {
  // Tooltips are used on desktop to show the child navigation elements
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      interactive: true,
      offset: [0, 0],
    })

  // Accordions are used on mobile to show the child navigation elements
  // The collapse state is maintained in the browser's localStorage (if it has it)
  const [isExpanded, setIsExpanded] = useState(() => {
    if (isBrowser) {
      if (window.localStorage) {
        return (
          window.localStorage.getItem(`header_parent_menu_item_state-${id}`) ===
          'true'
        )
      }
    }
    return false
  })

  const [isMounted, setIsMounted] = useState(false)

  // Fix for: initial state is not always rendered correctly due to SSR
  useEffect(() => {
    if (window.localStorage) {
      setIsExpanded(
        window.localStorage.getItem(`header_parent_menu_item_state-${id}`) ===
          'true'
      )
    }
    setIsMounted(true)
  }, [id])

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    collapseStyles,
    expandStyles,
  })
  const toggleProps = getToggleProps({
    onClick: () => {
      setIsExpanded((isExpanded) => {
        if (window.localStorage) {
          window.localStorage.setItem(
            `header_parent_menu_item_state-${id}`,
            String(!isExpanded)
          )
        }
        return !isExpanded
      })
    },
  })
  const collapseProps = getCollapseProps()

  return (
    <>
      {/* Desktop Version */}
      <Box sx={{ display: ['none', null, null, 'block'] }}>
        <Link
          variant="nav.header"
          ref={setTriggerRef}
          sx={{ cursor: 'pointer' }}
          as="span"
        >
          {title}
        </Link>
        {visible && (
          <Box ref={setTooltipRef} {...getTooltipProps()}>
            <Box
              sx={{
                padding: 2,
                bg: 'blue',
                borderRadius: 'default',
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  transform: 'translate(-50%, -100%)',
                  left: '50%',
                  width: 0,
                  height: 0,
                  borderStyle: 'solid',
                  borderWidth: '0px 6.5px 6.5px 6.5px',
                  borderColor: 'transparent',
                  borderBottomColor: 'blue',
                }}
              />
              {children}
            </Box>
          </Box>
        )}
      </Box>

      {/* Mobile & Tablet Version */}
      {isMounted && (
        <Box
          sx={{
            display: ['block', null, null, 'none'],
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '&:focus': {
                outline: 'none',
              },
            }}
            {...toggleProps}
          >
            <Link
              variant="nav.headerMobile"
              sx={{ cursor: 'pointer' }}
              as="span"
            >
              {title}
            </Link>
            <Icon
              source={isExpanded ? ChevronUp : ChevronDown}
              size={20}
              color="white"
            />
          </Box>
          <Box
            sx={{
              borderRadius: 'default',
              cursor: 'pointer',
            }}
            {...collapseProps}
          >
            {children}
          </Box>
        </Box>
      )}
    </>
  )
}

const ParentMenuItemNoChildItems = ({ to, title, onClick }) => {
  return (
    <>
      {/* Desktop Version */}
      <Box sx={{ display: ['none', null, null, 'block'] }}>
        <Link
          variant="nav.header"
          sx={{ cursor: 'pointer' }}
          as={RouterLink}
          to={to}
        >
          {title}
        </Link>
      </Box>

      {/* Mobile & Tablet Version */}
      <Box
        sx={{
          display: ['block', null, null, 'none'],
          '&:focus': {
            outline: 'none',
          },
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <Link
            variant="nav.headerMobile"
            sx={{ cursor: 'pointer' }}
            as={RouterLink}
            to={to}
          >
            {title}
          </Link>
        </Box>
      </Box>
    </>
  )
}

const CustomPhoneLink = (props) => (
  <Box
    as="a"
    sx={{
      fontSize: 1,
      fontWeight: 'bold',
      display: ['block', 'flex'],
      px: [2, null, null, 2],
      py: [3, null, null, 2],
      textDecoration: 'none',
      cursor: 'pointer',
      '&&': {
        color: ['white', null, null, 'colorBlue100'],
      },
    }}
    {...props}
  />
)

const Menu = ({ onClose = () => {} }) => {
  const [state, setState] = useSharedState()
  const [isCTAInPartnerMode, setIsCTAInPartnerMode] = useState(false)
  const [isCTAInNonTeilverkaufMode, setIsCTAInNonTeilverkaufMode] =
    useState(false)
  const routerLocation = useRouterLocation()

  const entryPath = state.entryPath || '/'

  useEffect(() => {
    setIsCTAInPartnerMode(
      (routerLocation.pathname || '').includes('/partner') ||
        entryPath.includes('partner')
    )
    setIsCTAInNonTeilverkaufMode(
      (routerLocation.pathname || '').includes('/beleihen') ||
        entryPath.includes('beleihen') ||
        (routerLocation.pathname || '').includes('/finanzieren') ||
        entryPath.includes('finanzieren')
    )
  }, [state, routerLocation])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          alignSelf: ['unset', null, null, 'flex-end'],
          marginBottom: ['0px', null, null, 2],
        }}
      >
        <Flex sx={{ my: [null, null, 2, 0], alignItems: 'center' }}>
          <Icon
            source={Phone}
            size={16}
            color={['white', null, null, 'colorBlue100']}
          />
          <PhoneLink as={CustomPhoneLink} />
        </Flex>
      </Box>

      <Flex flexDirection={['column', null, null, 'row']} flexGrow={1}>
        <Flex
          // this bottom padding is needed so the whole content is visible on mobile
          pb={['150px', null, null, 0]}
          ml={[0, null, null, -2]}
          justifyContent={[null, null, null, 'space-between']}
          flexGrow={1}
          flexDirection={['column', null, null, 'row']}
          alignItems={[null, null, null, 'center']}
        >
          <Box
            sx={{
              display: ['none', null, null, 'block'],
              transform: 'translateY(-8px)',
            }}
          >
            <Logo color={'colorBlue100'} />
          </Box>
          {/* <ParentMenuItemNoChildItems
            title="Teilverkauf"
            to="/"
            onClick={onClose}
          /> */}
          <ParentMenuItem title="Unser Service" id="unser-service">
            {/* <MenuItemInner to="/immobilienwertrechner" onClick={onClose}>
            Immobilienwertrechner
          </MenuItemInner> */}
            {/* <MenuItemInner to="/budgetrechner" onClick={onClose}>
              Budgetrechner
            </MenuItemInner>
            <Divider /> */}
            <MenuItemInner to="/beratung" onClick={onClose}>
              Beratung
            </MenuItemInner>
            <MenuItemInner to="/finanzierung" onClick={onClose}>
              Finanzierungs­lösungen
            </MenuItemInner>
          </ParentMenuItem>
          <ParentMenuItem title="Wissenswertes" id="wissenswertes">
            <MenuItemInner to="/blog" onClick={onClose}>
              Blog
            </MenuItemInner>
            <MenuItemInner to="/ratgeber" onClick={onClose}>
              Ratgeber
            </MenuItemInner>
            <MenuItemInner to="/faq/haeufige-fragen" onClick={onClose}>
              Häufige Fragen
            </MenuItemInner>
            <MenuItemInner to="/glossar" onClick={onClose}>
              Glossar
            </MenuItemInner>
          </ParentMenuItem>
          <ParentMenuItem title="Über Uns" id="uber-uns">
            <MenuItemInner to="/team" onClick={onClose}>
              Über Bambus
            </MenuItemInner>
            <MenuItemInner to="/werte" onClick={onClose}>
              Unsere Werte
            </MenuItemInner>
          </ParentMenuItem>

          <Button
            display={['none', 'none', 'none', 'flex']}
            py={1}
            as="a"
            href="#anmeldenTeilverkaufModal"
            onClick={() => {
              setState({
                ...state,
                trackEvent: 'header_cta_complete',
              })
              window.dataLayer?.push({
                event: 'header_cta_start',
              })
              window._paq?.push([
                'trackEvent',
                'header_cta_start',
                'header_cta_start',
              ])
            }}
            bg="blue"
            {...(isCTAInPartnerMode && partnerCTAProps)}
            {...(isCTAInNonTeilverkaufMode && {
              href: '#anmelden',
            })}
          >
            Unverbindlich anfragen
          </Button>

          {/* Desktop Login Button */}
          <Box sx={{ display: ['none', null, null, 'none'] }}>
            <Box sx={{ width: ['100%', 'auto'], mt: ['auto', 0] }}>
              <Button
                py={1}
                as="a"
                href={LOGIN_URL}
                target="_blank"
                variant="outline"
                rel="noopener noreferrer"
              >
                <Box as={User} width={14} mr={2} />
                Login
              </Button>
            </Box>
          </Box>

          {/* Mobile Login Button */}
          <Box
            sx={{
              display: ['none', null, null, 'none'],
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              padding: 3,
              backgroundColor: 'colorBlue100',
            }}
          >
            <Button
              py={1}
              as="a"
              href={LOGIN_URL}
              target="_blank"
              variant="outlineWhiteFlex"
              rel="noopener noreferrer"
              sx={{ width: '100%', maxWidth: '500px' }}
            >
              <Box as={User} width={14} mr={2} />
              Login
            </Button>
          </Box>

          <Box display={['none', null, null, 'none']}>
            <LocationDropdown />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

const Header = (props) => {
  const ref = useRef()
  const [open, setOpen] = useState(false)

  const handleToggle = () => setOpen(!open)

  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (open && ref.current) {
      disableBodyScroll(ref.current, { allowTouchMove: false })
    } else {
      clearAllBodyScrollLocks()
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [open])

  return (
    <Section
      ref={ref}
      pt={['0px', null, null, 3]}
      pb={['0px', null, null, 4]}
      sx={{
        display: 'flex',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: open ? 2147483002 : 2500,
        height: [
          open ? 'calc(var(--vh, 1vh) * 100 + 66px)' : 'auto',
          null,
          null,
          'auto',
        ],
        overflow: [open ? 'auto' : 'unset', null, null, 'unset'],
        bg: [open ? 'colorBlue100' : 'white', null, null, 'white'],
      }}
      {...props}
    >
      <Container sx={{ display: 'flex', position: 'relative', zIndex: 1 }}>
        <Row
          flexDirection={['column', null, null, 'row']}
          alignItems={[null, null, null, 'center']}
          flexWrap="nowrap"
          flexGrow={1}
          width="100%"
        >
          <Column
            size={[1, null, null, '0%']}
            display="flex"
            alignItems="center"
            py={[3, null, null, '15px']}
            sx={{
              position: ['sticky', null, null, 'static'],
              top: '0px',
              backgroundColor: [
                open ? 'colorBlue100' : 'white',
                null,
                null,
                'white',
              ],
              alignSelf: ['unset', null, null, 'flex-end'],
            }}
          >
            <Box
              sx={{
                display: ['block', null, null, 'none'],
              }}
            >
              <Logo
                color={[
                  open ? 'white' : 'colorBlue100',
                  null,
                  null,
                  'colorBlue100',
                ]}
              />
            </Box>

            <Box
              display={['flex', null, null, 'none']}
              alignItems="center"
              ml="auto"
              mr={-2}
              justifyContent="flex-end"
            >
              {/* <Box
                variant="buttons.outline"
                as="div"
                width={32}
                height={32}
                mx={2}
                px={0}
                py={0}
                sx={{
                  borderColor: 'colorBlue25',
                  boxShadow: 'none',
                }}
              >
                <LocationDropdown />
              </Box> */}

              <Button
                variant="outline"
                width={32}
                height={32}
                mx={2}
                px={0}
                py={0}
                sx={{ borderColor: 'colorBlue25', boxShadow: 'none' }}
                onClick={handleToggle}
              >
                <Icon
                  source={open ? Close : MenuIcon}
                  size={18}
                  iconProps={{ ...(open && { color: 'white' }) }}
                />
              </Button>
            </Box>
          </Column>

          <Column size={1} display="flex" flexGrow={1} py={0}>
            <Box
              sx={{
                display: [open ? 'flex' : 'none', null, null, 'block'],
                flexGrow: 1,
              }}
            >
              <Menu onClose={handleClose} />
            </Box>
          </Column>
        </Row>
      </Container>
    </Section>
  )
}

export default Header
