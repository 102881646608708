import React from 'react'

import { Box, Heading } from 'components'

const TrustPilot = () => {
  const ref = React.useRef()

  React.useEffect(() => {
    if (window.Trustpilot && ref.current) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="de-DE"
      data-template-id="56278e9abfbbba0bdcd568bc"
      data-businessunit-id="5da971f2875163000191ed6a"
      data-style-height="52px"
      data-style-width="100%"
    >
      <a
        href="https://de.trustpilot.com/review/bambus.io"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  )
}

const Trust = (props) => {
  return (
    <Box textAlign="center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 106 19"
        width={106}
      >
        <path
          fill="#558B6E"
          d="M0 4.7a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H4a4 4 0 01-4-4v-10z"
        />
        <path
          fill="#FFFBFC"
          d="M15 8c0-.2-.2-.3-.5-.3l-3.4-.5L9.5 4c-.2-.4-.8-.4-1 0L7 7.1l-3.4.6c-.2 0-.4.1-.5.3 0 .3 0 .5.2.6L5.7 11 5 14.5c0 .2 0 .5.2.6h.6L9 13.5l3.1 1.6h.6c.2-.2.3-.4.2-.6l-.6-3.5 2.5-2.5c.2 0 .2-.3.2-.5z"
        />
        <path
          fill="#558B6E"
          d="M22 4.7a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H26a4 4 0 01-4-4v-10z"
        />
        <path
          fill="#FFFBFC"
          d="M37 8c0-.2-.2-.3-.5-.3l-3.4-.5L31.5 4c-.2-.4-.8-.4-1 0L29 7.1l-3.4.6c-.2 0-.4.1-.5.3 0 .3 0 .5.2.6l2.5 2.4-.6 3.5c0 .2 0 .5.2.6h.6l3.1-1.6 3.1 1.6h.6c.2-.2.3-.4.2-.6l-.6-3.5 2.5-2.5c.2 0 .2-.3.2-.5z"
        />
        <path
          fill="#558B6E"
          d="M44 4.7a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H48a4 4 0 01-4-4v-10z"
        />
        <path
          fill="#FFFBFC"
          d="M59 8c0-.2-.2-.3-.5-.3l-3.4-.5L53.5 4c-.2-.4-.8-.4-1 0L51 7.1l-3.4.6c-.2 0-.4.1-.5.3 0 .3 0 .5.2.6l2.5 2.4-.6 3.5c0 .2 0 .5.2.6h.6l3.1-1.6 3.1 1.6h.6c.2-.2.3-.4.2-.6l-.6-3.5 2.5-2.5c.2 0 .2-.3.2-.5z"
        />
        <path
          fill="#558B6E"
          d="M66 4.7a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H70a4 4 0 01-4-4v-10z"
        />
        <path
          fill="#FFFBFC"
          d="M81 8c0-.2-.2-.3-.5-.3l-3.4-.5L75.5 4c-.2-.4-.8-.4-1 0L73 7.1l-3.4.6c-.2 0-.4.1-.5.3 0 .3 0 .5.2.6l2.5 2.4-.6 3.5c0 .2 0 .5.2.6h.6l3.1-1.6 3.1 1.6h.6c.2-.2.3-.4.2-.6l-.6-3.5 2.5-2.5c.2 0 .2-.3.2-.5z"
        />
        <path
          fill="#558B6E"
          d="M88 4.7a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H92a4 4 0 01-4-4v-10z"
        />
        <path
          fill="#FFFBFC"
          d="M103 8c0-.2-.2-.3-.5-.3l-3.4-.5L97.5 4c-.2-.4-.8-.4-1 0L95 7.1l-3.4.6c-.2 0-.4.1-.5.3 0 .3 0 .5.2.6l2.5 2.4-.6 3.5c0 .2 0 .5.2.6h.6l3.1-1.6 3.1 1.6h.6c.2-.2.3-.4.2-.6l-.6-3.5 2.5-2.5c.2 0 .2-.3.2-.5z"
        />
      </svg>
      <Heading.H4 as="p" mb={3}>
        „Hervorragend“
      </Heading.H4>
      <TrustPilot />
    </Box>
  )
}

export default Trust
