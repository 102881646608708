import React from 'react'
import { Link as RouterLink } from 'gatsby'
import has from 'lodash/has'
import { useLocation } from '@reach/router'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Box, Heading, Text, Button, Link } from 'components'
import TeilverkaufCalculator from 'components/TeilverkaufCalculator'

const richTextRendererOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Text as="span" fontWeight="bold">
        {text}
      </Text>
    ),
  },

  renderNode: {
    [BLOCKS.HEADING_1]: (_, children) => (
      <Heading.H2 as="h1" mt={6} mb={4} sx={{ '&:first-of-type': { mt: 0 } }}>
        {children}
      </Heading.H2>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Heading.H3 as="h2" mt={4} mb={3}>
        {children}
      </Heading.H3>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Heading.H4 as="h3" mt={4} mb={3}>
        {children}
      </Heading.H4>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Heading.H5 as="h4" mt={4} mb={3}>
        {children}
      </Heading.H5>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <Heading.H6 as="h5" mt={4} mb={3}>
        {children}
      </Heading.H6>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <Heading.H6 as="h6" mt={4} mb={3}>
        {children}
      </Heading.H6>
    ),

    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Text as="p" mb={4} sx={{ '&:last-of-type': { mb: 0 } }}>
        {children}
      </Text>
    ),

    [INLINES.HYPERLINK]: ({ data }, children) => {
      const url = data.uri

      return (
        <Link
          {...(url.startsWith('/')
            ? {
                as: RouterLink,
                to: url,
              }
            : {
                as: 'a',
                href: url,
              })}
        >
          {children}
        </Link>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      let url = node.data.target.fields.file.de.url
      return (
        <img
          src={'https:' + url}
          width="100%"
          alt={node.data.target.fields.title.de}
        />
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const fields = node.data.target.fields

      if (has(fields, 'table')) {
        return <Table data={fields} />
      }

      if (has(fields, 'url')) {
        return <CallToAction data={fields} />
      }

      if (has(fields, 'teilverkaufCalculatorId')) {
        return <TeilverkaufCalculator useAlwaysColumnLayout />
      }
    },
  },
}

const RichTextRenderer = ({ children }) => {
  return documentToReactComponents(children, richTextRendererOptions)
}

const Table = ({ data }) => {
  const {
    title: { de: title },
    table: {
      de: { tableData },
    },
  } = data

  const [tableHead, ...tableBody] = tableData

  return (
    <Box
      sx={{
        overflowX: 'auto',
        mt: 4,
        mb: 4,
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          minWidth: '100%',
          verticalAlign: 'middle',
        }}
      >
        <Box
          sx={{
            border: '1px solid',
            borderColor: 'colorBlue25',
            borderRadius: [0, 'large'],
            overflow: 'hidden',
          }}
        >
          <Box
            as="table"
            sx={{
              borderCollapse: 'collapse',
              tableLayout: 'fixed',
              minWidth: '100%',
            }}
          >
            <caption style={{ display: 'none' }}>{title}</caption>
            <thead>
              <tr>
                {tableHead.map((cell, cellIndex) => (
                  <Box
                    key={`c.${cellIndex}`}
                    as="th"
                    sx={{
                      px: [3, 4],
                      py: [1, 2],
                      bg: 'colorBlue5',
                      textAlign: 'left',
                    }}
                  >
                    {cell}
                  </Box>
                ))}
              </tr>
            </thead>

            <tbody>
              {tableBody.map((columns, rowIndex) => (
                <tr key={`r.${rowIndex}`}>
                  {columns.map((cell, cellIndex) => (
                    <Box
                      key={`c.${cellIndex}`}
                      as="td"
                      sx={{
                        width: columns.length,
                        bg: rowIndex % 2 === 0 ? 'colorBlue2' : 'transparent',
                        px: [3, 4],
                        py: [1, 2],
                        border: '2px solid',
                        borderColor: 'colorBlue10',
                        ...(cellIndex === 0 && {
                          borderLeftWidth: 0,
                        }),
                        ...(cellIndex === columns.length - 1 && {
                          borderRightWidth: 0,
                        }),
                        ...(rowIndex === tableBody.length - 1 && {
                          borderBottomWidth: 0,
                        }),
                      }}
                    >
                      {cell}
                    </Box>
                  ))}
                </tr>
              ))}
            </tbody>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
const CallToAction = ({ data }) => {
  const {
    text: { de: text },
    url: { de: url },
    variant: { de: variant } = {
      de: 'main',
    },
    serviceDimension,
  } = data

  const location = useLocation()

  const searchParams =
    serviceDimension && serviceDimension.de
      ? `?service_dimension=${serviceDimension.de}`
      : ''

  return (
    <Box
      sx={{
        mt: 5,
        mb: 5,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        sx={{
          textAlign: 'center',
        }}
        variant={variant}
        {...(url.startsWith('/')
          ? {
              as: RouterLink,
              to: url,
            }
          : url.startsWith('#')
          ? {
              as: RouterLink,
              to: `${location.pathname}${searchParams}${url}`,
            }
          : {
              as: 'a',
              href: url,
            })}
      >
        {text}
      </Button>
    </Box>
  )
}

export default RichTextRenderer
