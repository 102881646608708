import React from 'react'
import { Link as RouterLink } from 'gatsby'

import { Box } from 'components'
import { useSharedState } from 'context/sharedState'

import Bambus from 'images/bambus.svg'

const Logo = (props) => {
  const { color = 'colorBlue100', responsive = false } = props
  const [{ entryPath }] = useSharedState()

  return (
    <Box
      as={RouterLink}
      to={entryPath || '/'}
      sx={{
        display: 'flex',
        mt: '-3px',
        svg: {
          width: ['initial', null, null, '150px'],
          height: ['initial', null, null, '32px'],
        },
        '#bambus': { fill: color },
        '#ambus': {
          display: [responsive ? 'none' : null, 'block'],
        },
      }}
    >
      <Bambus />
    </Box>
  )
}

export default Logo
