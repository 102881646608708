import React from 'react'
import { CheckCircle, X } from 'icons'

import Logo from 'components/Logo'
import LeadCTAButton from 'components/LeadCTAButton'
import {
  Section,
  Container,
  Row,
  Column,
  Box,
  Flex,
  Heading,
  Text,
  Icon,
} from 'components'

const Card = ({ primary, children }) => (
  <Column
    size={[1, null, null, 1 / 3]}
    display="flex"
    justifyContent="center"
    px={0}
  >
    <Box
      variant={`card.${primary ? 'primary' : 'secondary'}`}
      bg={primary ? 'colorBlue100' : 'white'}
      px={primary ? [6, 6] : [4, 6]}
      py={primary ? [4, 8] : [4, 5]}
      mx={[primary ? -3 : 0, 0]}
      mt={[primary ? -3 : -3, 0]}
      maxWidth={400}
      flexGrow={1}
    >
      {children}
    </Box>
  </Column>
)

const Comparison = ({
  title,
  items = [],
  ctaButtonID,
  ctaExtraProps = {},
  leadModalType = 'standard_offer',
  backgroundColor = 'colorBlue2',
}) => {
  return (
    <Section spacing="large" bg={backgroundColor}>
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center">{title}</Heading.H2>
        </Section.Header>

        <Section.Body>
          <Row flexWrap="wrap" alignItems="center" mx={[0, 0]}>
            {items.map(({ primary, title, details }, index) => {
              return (
                <Card key={index} primary={primary}>
                  <Heading.H3 fontWeight="display">
                    {title || <Logo color="white" responsive={false} />}
                  </Heading.H3>
                  <Text mt={4} {...(primary && { color: 'white' })}>
                    {details.map((argument, index) => (
                      <Flex sx={{ alignItems: 'center' }} py={2} key={index}>
                        <Icon
                          source={primary ? CheckCircle : X}
                          color={primary ? '#83B692' : 'red'}
                          size={18}
                          mr={3}
                        />
                        {argument}
                      </Flex>
                    ))}
                  </Text>
                </Card>
              )
            })}
          </Row>
        </Section.Body>

        <Section.Footer sx={{ textAlign: 'center' }} mt={[6]}>
          <LeadCTAButton
            ctaButtonID={ctaButtonID}
            leadModalType={leadModalType}
            variant="main"
            sx={{
              width: ['100%', 'auto'],
              maxWidth: 300,
              mx: ['auto', 0],
            }}
            {...ctaExtraProps}
          >
            Unverbindlich anfragen
          </LeadCTAButton>
        </Section.Footer>
      </Container>
    </Section>
  )
}

export default Comparison
