import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ProgressiveImage from 'gatsby-image'

import { Section, Container, Box } from 'components'

const Map = ({ noPaddingTop = false }) => {
  const { map, mapMobile } = useStaticQuery(
    graphql`
      query {
        map: file(relativePath: { eq: "map.png" }) {
          childImageSharp {
            fluid(maxWidth: 1160, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        mapMobile: file(relativePath: { eq: "map-mobile-small.png" }) {
          childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  const sources = [
    mapMobile.childImageSharp.fluid,
    {
      ...map.childImageSharp.fluid,
      media: `(min-width: 640px)`,
    },
  ]

  return (
    <Section bg="colorBlue2" py={[0]} noPaddingTop={noPaddingTop}>
      <Container>
        <Box sx={{ borderTop: '1px solid', borderTopColor: 'colorBlue10' }}>
          <ProgressiveImage fluid={sources} />
        </Box>
      </Container>
    </Section>
  )
}

export default Map
