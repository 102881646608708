import React from 'react'
import RCSlider, {
  Handle as RCHandle,
  SliderProps as RCSliderProps,
} from 'rc-slider'
import styled from '@emotion/styled'
import 'rc-slider/assets/index.css'

const RAIL_SIZE = 3
const HANDLER_SIZE = 24

const SliderWrapper = styled.div``

const SliderLabel = styled.label`
  display: block;
  margin-bottom: 14px;

  font-size: 18px;
  color: #012a59;
`

const SliderError = styled.p`
  margin-bottom: 0;

  font-size: 18px;
  color: #db5461;
`

const SliderHandler = styled(RCHandle)`
  width: ${HANDLER_SIZE}px;
  height: ${HANDLER_SIZE}px;
  margin-top: ${RAIL_SIZE / 2 - HANDLER_SIZE / 2}px;

  background: #fffbfc;
  border-width: 1px;
  border-color: rgba(1, 42, 89, 0.25);

  &:focus {
    border-color: ${({ hasError }) =>
      !hasError ? 'rgba(1, 42, 89, 0.25)' : '#DB5461'};
  }

  &:hover,
  &:active {
    border-color: rgba(1, 42, 89, 0.25);

    box-shadow: 0px 3px 6px rgba(147, 147, 147, 0.05),
      0px 6px 12px rgba(147, 147, 147, 0.1);
  }
`

const SliderValues = styled.div`
  display: flex;
`

const ExtremumValue = styled.p`
  flex: 1;

  margin-top: 14px;
  margin-bottom: 0;

  font-size: 14px;
  @media (min-width: 500px) {
    font-size: 18px;
  }

  color: rgba(1, 42, 89, 0.5);
  text-align: center;

  &:first-of-type {
    text-align: left;
  }

  &:last-of-type {
    text-align: right;
  }
`

const CurrentValue = styled(ExtremumValue)`
  color: ${({ hasError }) => (hasError ? '#DB5461' : '#012A59')};
  font-weight: bold;
`

const commonRailStyles = {
  height: 3,
}

const railStyle = {
  ...commonRailStyles,
  background: 'rgba(1, 42, 89, 0.25)',
}

const trackStyle = {
  ...commonRailStyles,
  background: '#012A59',
}

export const Slider = ({
  label,
  error,
  minLabel,
  maxLabel,
  valueFormatter = (value) => String(value),
  onChange = (value) => value,
  ...rest
}) => {
  const hasError = typeof error === 'string'
  const [value, setValue] = React.useState(rest.defaultValue || 0)

  const handleChange = React.useCallback(
    (newValue) => {
      setValue(newValue)
      onChange(newValue)
    },
    [onChange]
  )

  return (
    <SliderWrapper>
      <SliderLabel>{label}</SliderLabel>

      <RCSlider
        {...rest}
        trackStyle={[trackStyle]}
        railStyle={railStyle}
        handle={(props) => <SliderHandler hasError={hasError} {...props} />}
        value={value}
        onChange={handleChange}
      />

      <SliderValues>
        <ExtremumValue>{minLabel || rest.min}</ExtremumValue>
        <CurrentValue hasError={hasError}>{valueFormatter(value)}</CurrentValue>
        <ExtremumValue>{maxLabel || rest.max}</ExtremumValue>
      </SliderValues>

      {hasError && <SliderError>{error}</SliderError>}
    </SliderWrapper>
  )
}

export default Slider
